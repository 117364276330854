.mapContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

#map {
  position: fixed;
  top: 0;
  bottom: 0;
  display: block;
}

.mapboxgl-popup-content .child {
  background-color: #343a40;
}

.mapboxgl-popup-content {
  background-color: #343a40;
  color: #000;
  margin-left: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 5px;
  min-height: 300px;
  min-width: 300px;
  max-height: 500px;
  max-width: 960px;
  overflow-x: hidden;
  overflow-x: auto;
  z-index: 1000;
}

.Collapsible {
  background-color: #424242;
  color: #fff;
  margin-left: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.Collapsible__trigger {
  padding: 5px;
}

.Collapsible__contentInner {
  background-color: #6f777f;
  padding: 5px;
}
