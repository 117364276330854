/* aside {
  overflow-x: hidden;
  width: 0;
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  background-color: #2d2d2d;
  transition: width 0.5s ease-in-out;
}

.drawerMin {
  width: 70px;
}

.drawerOpen {
  width: 240px;
}

main {
  position: absolute;
  background: #343a40;
  top: 0px;
  min-width: 100%;
  margin-left: 0;
  bottom: 0;
  overflow-y: auto;
  transition: margin-left 0.5s ease-in-out;
} */

/* .mainMin {
  margin-left: 70px;
  min-width: calc(100% - 70px);
  transition: margin-left 0.5s ease-in-out;
}

.mainOpen {
  margin-left: 240px;
  min-width: calc(100% - 240px);
  transition: margin-left 0.5s ease-in-out;
} */

.noPadding {
  padding: 0px !important;
}
