.modal-content {
  background-color: #343a40;
}

.card-header-tabs {
  margin: 10px;
}

.nav-item {
  background-color: #fff;
  margin: 10px;
}

.close {
  color: #fff;
}
