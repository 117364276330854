.nav-bar {
  margin-right: auto !important;
}

.menu-icon {
  position: relative;
  font-size: 1.2rem;
  left: 5px;
  margin-right: 30px;
}

.nav-button {
  margin-left: 10px;
}

.nav-text {
  position: relative;
  font-size: 1.5rem;
  right: 5px;
  margin-left: 15px;
}

.nav-sign-out-button {
  position: fixed;
  bottom: 10px;
  left: 20px;
}

.nav-sign-in-button {
  position: fixed;
}
